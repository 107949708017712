import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TvModal from "./TvModal";

export default function Television() {
  return (
    <div>
      <div className="tv-container">
        <Container>
          <Row>
            <Col>
              <div className="tv-heading">
                <h2>Telewizja</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tv-text-box">
                <h3>Bogaty wybór kanałów</h3>
                <p>
                  Nasza cyfrowa telewizja to jedna z najlepszych ofert
                  dostępnych na rynku. 225 kanałów w cyfrowej jakości obrazu i
                  dźwięku, w tym 93 w jakości HD, pośród których każdy z
                  domowników znajdzie coś dla siebie.
                </p>
              </div>
            </Col>
            <Col>
              <div className="tv-text-box">
                <h3>Multimedialny dekoder</h3>
                <p>
                  Możesz nagrywać, przewijać i zatrzymywać swoje ulubione
                  programy - dzięki usłudze domowej nagrywarki. Możesz też
                  obejrzeć swoje ulubione audycje nawet 3 dni do tyłu.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="tv-button">
          <TvModal />
        </div>
      </div>
    </div>
  );
}
