import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MobileModal from "./MobileModal";

export default function Mobile() {
  return (
    <div className="mobile-container">
      <Container>
        <Row>
          <Col>
            <div className="mobile-heading">
              <h2>Telefon komórkowy</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mobile-text-box">
              <h3>Bez ograniczeń</h3>
              <p>
                Nielimitowane rozmowy, SMSy i MMSy, niezależnie od pakietu jaki
                wybierzesz. Istnieje możliwość skorzystania z technologii 5G.
                Usługa działa na nadajnikach Play.
              </p>
            </div>
          </Col>
          <Col>
            <div className="mobile-text-box">
              <h3>Bez zmartwień</h3>
              <p>
                Nie chcesz zmieniać swojego numeru? To nie problem! Możesz
                przenieść numer telefonu od dowolnego operatora. Zgłoś się do
                nas a my załatwimy za Ciebie wszystkie formalności.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="mobile-offer-button">
        <MobileModal />
      </div>
    </div>
  );
}
