import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Range() {
  return (
    <div className="range-page">
      <h2 className="range-title">Zasięg naszej sieci</h2>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1Tgg0vKOyuuJ6kKitk3ug5lu25KYWnk8&ehbc=2E312F"
        width="640"
        height="480"
        title="aMediaMap"
        className="iframe"
      ></iframe>
      <div className="range-text">
        Poniżej znajdą Państwo listę miejscowości w których świadczymy usługi
        światłowodowe.{" "}
      </div>
      <div className="range-list">
        <p className="range-explanation">
          Jeżeli nie widzisz swojej miejscowości na liście, ale na mapie jest
          blisko/ w zasięgu, koniecznie skontakuj się z naszym biurem, a my
          sprawdzimy co możemy dla Ciebie zrobić.
        </p>
        <Container>
          <Row>
            <Col>
              <div className="range-columns">
                <p className="range-name">Gmina Błaszki:</p>
                <div className="range-city-name">
                  <p>Brudzew,</p>
                  <p>Cienia,</p>
                  <p>Kociołki,</p>
                  <p>Lubanów,</p>
                  <p>*Maciszewice,</p>
                  <p>Równa,</p>
                  <p>*Smaszków,</p>
                  <p>Zawady,</p>
                  <p>Żelisław,</p>
                  <p>Żelisław-Kolonia</p>
                </div>
                <p className="range-name">Miasto Błaszki:</p>
                <div className="range-city-name">
                  <p>Pl. Niepodległości,</p>
                  <p>Pl. Sulwińskiego, </p>
                  <p>ul.Polna,</p>
                  <p>ul.Przemysłowa,</p>
                  <p>ul.Sieradzka</p>
                </div>
                <p className="range-name">Gmina Sieradz:</p>
                <div className="range-city-name">
                  <p>Biskupice,</p>
                  <p>Bogumiłów,</p>
                  <p>Bogumiłów-Kolonia,</p>
                  <p>Charłupia Mała,</p>
                  <p>Chojne,</p>
                  <p>*Dąbrówka,</p>
                  <p>Dzierlin,</p>
                  <p>Dzigorzew,</p>
                  <p>*Grądy,</p>
                  <p>*Łosieniec,</p>
                  <p>*Mnichów,</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="range-columns">
                <div className="range-city-name">
                  <p>*Okręglica,</p>
                  <p>*Sokołów,</p>
                  <p>*Sucha,</p>
                  <p>Wiechucice,</p>
                  <p>Wiechutki</p>
                </div>
                <p className="range-name">Miasto Sieradz:</p>
                <div className="range-city-name">
                  <p>ul. Botaniczna,</p>
                  <p>ul. Ceramiczna,</p>
                  <p>ul. Działkowa,</p>
                  <p>ul. Graeve,</p>
                  <p>ul. Krakowskie Przedmieście,</p>
                  <p>ul. Niska,</p>
                  <p>ul. Oksińskiego,</p>
                  <p>ul. Pod Skarpą,</p>
                  <p>ul. P.O.W.</p>
                  <p>ul. Słoneczna,</p>
                  <p>ul. Spacerowa,</p>
                  <p>ul. Wesoła,</p>
                  <p>ul. Wierzbowa,</p>
                  <p>ul. Wysoka,</p>
                  <p>ul. Złotej Jesieni,</p>
                  <p>ul. Zakładników,</p>
                  <p>ul. 1-Maja</p>
                </div>
                <p className="range-name">Gmina Warta:</p>
                <div className="range-city-name">
                  <p>Bartochów,</p>
                  <p>Małków,</p>
                  <p>Piotrowice</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="range-columns">
                <p className="range-name">Gmina Wróblew:</p>
                <div className="range-city-name">
                  <p>Bliźniew,</p>
                  <p>Charłupia Wielka,</p>
                  <p>Dąbrówka,</p>
                  <p>*Drzązna,</p>
                  <p>*Dziebędów,</p>
                  <p>*Gaj,</p>
                  <p>Inczew,</p>
                  <p>*Józefów</p>
                  <p>Kobierzycko,</p>
                  <p>*Kościerzyn,</p>
                  <p>*Łubna-Jakusy,</p>
                  <p>*Łubna-Jarosłaj,</p>
                  <p>Oraczew,</p>
                  <p>*Oraczew Mały,</p>
                  <p>Próchna,</p>
                  <p>*Rakowice,</p>
                  <p>Sadokrzyce,</p>
                  <p>*Sędzice,</p>
                  <p>Słomków Mokry,</p>
                  <p>Słomków Suchy,</p>
                  <p>Smardzew,</p>
                  <p>*Sudoły,</p>
                  <p>*Tworkowizna,</p>
                  <p>Tworkowizna Oraczewska,</p>
                  <p>Wągłczew,</p>
                  <p>*Wągłczew-Kolonia,</p>
                  <p>Wróblew</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <p className="range-text-bottom">
          * - na chwilę obecną dostępny jedynie Internet radiowy
        </p>
      </div>
    </div>
  );
}
