import React from "react";
import Internet from "./Internet";
import Television from "./Television";
import Gallery from "./Gallery";
import Mobile from "./Mobile";

export default function Home() {
  return (
    <div className="home-page">
      <Gallery />
      <Internet />
      <Television />
      <Mobile />
    </div>
  );
}
