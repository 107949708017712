import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdPhonelinkRing, MdMonitor, MdWork } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { TbDeviceComputerCamera } from "react-icons/tb";
import { FaSignal } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import projekt from "./images/projekt.png";
import logoorange from "./images/logo_orange.png";

export default function About() {
  return (
    <div>
      <div className="about-container">
        <div className="about-first-box">
          <div className="about-orange-logo">
            <img src={logoorange} alt="a-Media logo"></img>
          </div>
          <div className="about-text">
            jest rodzinną, prężnie rozwijającą się elastyczną firmą, która wraz
            ze swoimi partnerami realizuje duże projekty z zakresu IT. Jesteśmy
            największym lokalnym dostawcą usług dostępu do Internetu i telefonii
            VoIP. Świadczymy kompleksowe usługi dla klientów indywidualnych oraz
            biznesowych.
          </div>
        </div>
      </div>
      <div className="mission-container">
        <Container>
          <div className="mission-text">
            Misja <b className="about-text-main">a-Media</b> to przede wszystkim
            rozwijanie sieci w taki sposób aby umożliwić dostęp do nowych
            technologii mieszkańcom małych miejscowości i wsi.
          </div>
          <Row>
            <Col>
              <div className="mission-box">
                <FaSignal className="mission-icon" />
                <div className="mission-title">Internet</div>
              </div>
            </Col>
            <Col>
              <div className="mission-box">
                <BsTelephone className="mission-icon" />
                <div className="mission-title">VoIP</div>
              </div>
            </Col>
            <Col>
              <div className="mission-box">
                <MdMonitor className="mission-icon" />
                <div className="mission-title">Telewizja</div>
              </div>
            </Col>
            <Col>
              <div className="mission-box">
                <TbDeviceComputerCamera className="mission-icon" />
                <div className="mission-title">Monitoring</div>
              </div>
            </Col>
            <Col>
              <div className="mission-box">
                <MdPhonelinkRing className="mission-icon" />
                <div className="mission-title">Komórka</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-container">
        <div className="about-first-box">
          <div className="about-icon">
            <GiBrain />
          </div>
          <div className="about-text">
            <b className="about-text-main">Wiedza,</b> którą szczyci się firma
            to przede wszystkim wysokie kompetencje pracowników i
            współpracowników, doświadczenie zdobyte podczas realizacji wielu
            zaawansowanych projektów informatycznych oraz kilkunastoletnie
            doświadczenie jej własciciela wyniesione z pracy w branży IT.
            Wszystko to potwierdzone certyfikatami oraz listami referencyjnymi
            od największych firm z rynku IT w Polsce oraz wielu klientów
            końcowych.
          </div>
        </div>
      </div>
      <div className="about-container">
        <div className="about-first-box">
          <div className="about-icon">
            <MdWork />
          </div>
          <div className="about-text">
            <b className="about-text-main">Doświadczenie </b>
            firmy w realizacji projektów informatycznych wynika z ponad
            piętnastoletniej obecności rynku informatycznym i realizacji wielu
            ogólnopolskich kontraktów. Od 2002 roku zajmujemy się
            najnowocześniejszymi bezprzewodowymi technologiami pozwalającymi nie
            tylko świadczyć usługi dostępu do Internetu i usług telefonii VoIP
            lokalnie, ale również budowę rozległych sieci wlan na terenie całego
            kraju.
          </div>
        </div>
      </div>
      <div className="about-container">
        <div className="about-first-box">
          <div className="about-icon">
            <VscWorkspaceTrusted />
          </div>
          <div className="about-text">
            <b className="about-text-main">Satysfakcja </b> klienta oraz
            partnerów z nami współpracujących jest naszym głównym celem.
            Elastyczne podejście do potrzeb klienta jest cechą charakterystyczną
            sposobu naszego działania.
          </div>
        </div>
      </div>
      <div className="project-picture">
        <img src={projekt} alt="projekt unijny" />
      </div>
    </div>
  );
}
