import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./images/logo.png";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Range from "./Range";
import Home from "./Home";
import InternetInfo from "./InternetInfo";
import Logo3pik from "./images/logo3pik.png";

export default function Navi() {
  return (
    <BrowserRouter>
      <Navbar bg="dark" expand="md" variant="dark" bsPrefix="navbar">
        <Container fluid>
          <Navbar.Brand>
            <img src={logo} className="logo" alt="logo" width={50}></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link as={Link} to={"/Home"}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} to={"/About"}>
                O Nas
              </Nav.Link>
              <Nav.Link as={Link} to={"/Range"}>
                Zasięg
              </Nav.Link>
              <Nav.Link as={Link} to={"/Contact"}>
                Kontakt
              </Nav.Link>
              <Nav.Link href="https://poczta.lh.pl/" target="{_blank}">
                Poczta
              </Nav.Link>
              <Nav.Link href="https://ebok.amedia.pl/" target="{_blank}">
                E-BOK
              </Nav.Link>
            </Nav>
            <Nav>
              <Navbar.Brand
                href="http://czaja.pzbs.pl/sieradz/index.php"
                target="{_blank}"
              >
                <img
                  src={Logo3pik}
                  width="80"
                  height="40"
                  className="d-inline-block align-top logo3pik"
                  alt="Brigde"
                />
              </Navbar.Brand>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/InternetInfo" element={<InternetInfo />} />
          <Route exact path="/range" element={<Range />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
