import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";

function MobileModal() {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      {values.map((v, idx) => (
        <Button
          key={idx}
          className="me-2 mb-2"
          variant="outline-secondary"
          onClick={() => handleShow(v)}
        >
          Sprawdź ofertę tutaj
          {typeof v === "string" && `below ${v.split("-")[0]}`}
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Telefony komórkowe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mobile-modal-container">
            <div className="mobile-modal-title">
              <h4>Abonamenty oparte o nadajniki Play</h4>
            </div>
            <div className="mobile-modal-main">
              <Container>
                <Row>
                  <Col>
                    <div className="mobile-modal-box">
                      <div className="mobile-modal-box-top-basic">
                        <h3 className="mobile-modal-name">Basic</h3>
                        <div className="mobile-modal-price">25,00 </div>
                        <div className="mobile-modal-currency">zł / mies.</div>
                      </div>
                      <div className="mobile-modal-description-container">
                        <div className="mobile-modal-details-first">
                          <b>bez limitu</b>
                          <br /> na domowe w PL
                        </div>
                        <div className="mobile-modal-details-second">
                          <b>bez limitu </b>
                          <br /> na komórki w PL
                        </div>
                        <div className="mobile-modal-details-first">
                          SMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-second">
                          MMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-first">
                          2GB LTE w cenie
                        </div>
                        <div className="mobile-modal-details-second">
                          Roaming UE
                        </div>
                        <div className="mobile-modal-details-first">
                          Internet w UE 2GB
                        </div>
                        <div className="mobile-modal-details-second">
                          Cena przy umowie
                          <br /> na 24 miesiące
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mobile-modal-box">
                      <div className="mobile-modal-box-top-mini">
                        <h3 className="mobile-modal-name">Mini</h3>
                        <div className="mobile-modal-price">30,00 </div>
                        <div className="mobile-modal-currency">zł / mies.</div>
                      </div>
                      <div className="mobile-modal-description-container">
                        <div className="mobile-modal-details-first">
                          <b>bez limitu</b>
                          <br /> na domowe w PL
                        </div>
                        <div className="mobile-modal-details-second">
                          <b>bez limitu </b>
                          <br /> na komórki w PL
                        </div>
                        <div className="mobile-modal-details-first">
                          SMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-second">
                          MMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-first">
                          10GB LTE w cenie
                        </div>
                        <div className="mobile-modal-details-second">
                          Roaming UE
                        </div>
                        <div className="mobile-modal-details-first">
                          Internet w UE 2GB
                        </div>
                        <div className="mobile-modal-details-second">
                          Cena przy umowie
                          <br /> na 24 miesiące
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mobile-modal-box">
                      <div className="mobile-modal-box-top-midi">
                        <h3 className="mobile-modal-name">Midi</h3>
                        <div className="mobile-modal-price">40,00 </div>
                        <div className="mobile-modal-currency">zł / mies.</div>
                      </div>
                      <div className="mobile-modal-description-container">
                        <div className="mobile-modal-details-first">
                          <b>bez limitu</b>
                          <br /> na domowe w PL
                        </div>
                        <div className="mobile-modal-details-second">
                          <b>bez limitu </b>
                          <br /> na komórki w PL
                        </div>
                        <div className="mobile-modal-details-first">
                          SMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-second">
                          MMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-first">
                          25GB LTE w cenie
                        </div>
                        <div className="mobile-modal-details-second">
                          Roaming UE
                        </div>
                        <div className="mobile-modal-details-first">
                          Internet w UE 2GB
                        </div>
                        <div className="mobile-modal-details-second">
                          Cena przy umowie
                          <br /> na 24 miesiące
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="mobile-modal-box">
                      <div className="mobile-modal-box-top-maxi">
                        <h3 className="mobile-modal-name">Maxi</h3>
                        <div className="mobile-modal-price">55,00 </div>
                        <div className="mobile-modal-currency">zł / mies.</div>
                      </div>
                      <div className="mobile-modal-description-container">
                        <div className="mobile-modal-details-first">
                          <b>bez limitu</b>
                          <br /> na domowe w PL
                        </div>
                        <div className="mobile-modal-details-second">
                          <b>bez limitu </b>
                          <br /> na komórki w PL
                        </div>
                        <div className="mobile-modal-details-first">
                          SMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-second">
                          MMS <b>bez limitu </b>
                        </div>
                        <div className="mobile-modal-details-first">
                          50GB LTE w cenie
                        </div>
                        <div className="mobile-modal-details-second">
                          Roaming UE
                        </div>
                        <div className="mobile-modal-details-first">
                          Internet w UE 2GB
                        </div>
                        <div className="mobile-modal-details-second">
                          Cena przy umowie
                          <br /> na 24 miesiące
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MobileModal;
