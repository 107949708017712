import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navi from "./Navigation";
import secondlogo from "./images/secondlogo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import pzbslogo from "./images/pzbslogo.png";
import sdzlogo from "./images/sdzlogo.png";
import lzbslogo from "./images/lzbslogo.gif";

function App() {
  return (
    <div className="App">
      <Navi />
      <div className="contact-secondary">
        <Container>
          <Row>
            <Col>
              <img
                src={secondlogo}
                className="logo"
                alt="logo"
                width={120}
              ></img>
            </Col>
            <Col>
              <div className="contact-footer">
                <p className="contact-footer-title">Biuro obsługi klienta</p>
                <p>
                  ul. Polskiej Organizacji Wojskowej 6<br />
                  98-200 Sieradz <br />
                  Pn. - Pt.: 8:00 - 17:00
                </p>
                <BsFillTelephoneFill />
                <span>+43 65 65 000</span> <br />
                <MdEmail />
                <span>biuro@amedia.pl</span>
              </div>
            </Col>
            <Col>
              <div className="contact-footer">
                <p className="contact-footer-title">Dział płatności </p>
                <p>
                  <BsFillTelephoneFill />
                  +43 65 65 000 <br />
                  Czynne: 08.00 - 16.00
                </p>
                <p className="contact-footer-title">Dział techniczny</p>
                <p>
                  <BsFillTelephoneFill />
                  +48 602 741 804 <br />
                  Czynne: 09.00 - 17.00
                </p>
              </div>
            </Col>
            <Col>
              <div className="contact-footer">
                <div className="contact-footer-title">
                  Zaprzyjaźnione strony:
                </div>
                <div className="footer-friends-logo">
                  <a
                    href="https://www.pzbs.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={pzbslogo} alt="PZBS logo" />
                  </a>
                  <a
                    href="https://stellar-bombolone-578535.netlify.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={sdzlogo} alt="Sieradz Brydz" />
                  </a>
                  <a
                    href="https://www.lzbs.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={lzbslogo} alt="LZBS logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer>
        <div className="footer">
          COPYRIGHT © 2022{" "}
          <a
            href="https://peaceful-sherbet-a83583.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            Izabela Łaszczuk
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
