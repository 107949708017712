import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";

function TvModal() {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const tvPackages = [
  {
    name: 'Socjalny',
    price: '9,90',
    currency: 'zł / mies.',
    channels: 72,
    channelName: 'kanały',
    hdChannels: 49,
  },
  {
    name: 'Ekonomiczny',
    price: '32,90',
    currency: 'zł / mies.',
    channels: 125,
    channelName: 'kanałów',
    hdChannels: 93,
  },
  {
    name: 'Startowy',
    price: '49,00',
    currency: 'zł / mies.',
    channels: 157,
    channelName: 'kanałów',
    hdChannels: 118,
  },
  {
    name: 'Optymalny',
    price: '70,00',
    currency: 'zł / mies.',
    channels: 186,
    channelName: 'kanałów',
    hdChannels: 139,
  },
  {
    name: 'Bogaty',
    price: '86,00',
    currency: 'zł / mies.',
    channels: 203,
    channelName: 'kanały',
    hdChannels: 152,
  },
];

  return (
    <>
      {values.map((v, idx) => (
        <Button
          key={idx}
          className="me-2 mb-2"
          variant="outline-secondary"
          onClick={() => handleShow(v)}
        >
          Sprawdź ofertę tutaj
          {typeof v === "string" && `below ${v.split("-")[0]}`}
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Telewizja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tvModal-container">
            <div className="tvModal-title">
              <h4>Wybierz pakiet dopasowany do Twoich potrzeb</h4>
            </div>

            <div className="mobile-modal-main">
              <Container>
                <Row>
                     {tvPackages.map((tvPackage, index) => (
        <Col key={index}>
          <div className="tvModal-box">
            <div className="tvModal-box-top-mini">
              <h3 className="tvModal-name">{tvPackage.name}</h3>
              <span className="tvModal-price">{tvPackage.price} </span>
              <span className="tvModal-currency">{tvPackage.currency}</span>
            </div>
            <div className="tvModal-description-container">
              <div className="tvModal-details-first tvModal-channels-quantity">
                <b>{tvPackage.channels}</b> {tvPackage.channelName}
              </div>
              <div className="tvModal-details-second">
                <b>{tvPackage.hdChannels}</b> HD
              </div>
              <div className="tvModal-details-first">Pause TV</div>
              <div className="tvModal-details-second">nPVR</div>
              <div className="tvModal-details-first">CatchUP</div>
              <div className="tvModal-details-second">Ulubione</div>
              <div className="tvModal-details-first">TV Online</div>
            </div>
          </div>
        </Col>
      ))}
                </Row>
                <Row>
                  <div className="tvModal-more-info">
                    <div>
                      * Cena nie zawiera opłat za dostęp do platformy oraz
                      dzierżawy STB
                    </div>
                    <div>
                      <a
                        href="https://mmsc.metroport.pl/plugin/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tvModal-channels-link"
                      >
                        Zobacz listę dostępnych kanałów
                      </a>
                      <br/>
                      <a href="/channelList.pdf" target="_blank" 
                        className="tvModal-channels-link"
                        rel="noopener noreferrer">Lista kanałów PDF</a>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="tvModal-secondrow">
                      <div className="tvModal-secondrow-title">Legenda:</div>
                      <div>
                        <b>Pause TV </b> - Cofanie aktualnego programu nawet o
                        godzinę
                      </div>
                      <div>
                        <b>nPVR </b>- Nagrywanie w chmurze (pomimo braku dysku w
                        dekoderze !)
                      </div>
                      <div>
                        <b>CatchUP </b>- Odtwarzanie zakończonych programów do 7
                        dni wstecz
                      </div>
                      <div>
                        <b>Ulubione </b>- Nie trać czasu na przeglądanie całej
                        listy kanałów. Stwórz listę ulubionych kanałów i
                        przełączaj się tylko pomiędzy nimi.
                      </div>
                      <div>
                        <b>TV Online </b>- Odtwarzaj TV na Smartfonie, Tablecie
                        i Laptopie{" "}
                        <a
                          href="https://metrotv.pl/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://metrotv.pl/
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TvModal;
