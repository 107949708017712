import React from "react";
import Button from "react-bootstrap/Button";

export default function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-main">
        <div className="contact-questions">
          Zainteresowany ofertą? Masz pytania?
        </div>
        <div className="contact-exclamation">Skontaktuj się z nami!</div>
        <div className="contact-buttons">
          <Button variant="outline-secondary" size="lg" bsPrefix="btn">
            +43 65 65 000
          </Button>{" "}
          <Button
            variant="outline-secondary"
            size="lg"
            bsPrefix="btn"
            href="mailto:biuro@amedia.pl"
          >
            biuro@amedia.pl
          </Button>
        </div>
      </div>
    </div>
  );
}
