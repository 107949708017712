import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Internet() {
  return (
    <div className="internet-container">
      <Container>
      {/* <div className="newsSection">
        <div className="newsTitle">Ogłoszenie</div>
        <div className="newsDescription">Z okazji Świąt Bożego Narodzenia
oraz zbliżającego się Nowego Roku
pragniemy serdecznie podziękować
za dotychczasową współpracę
oraz złożyć Naszym Klientom,
Przyjaciołom i Sympatykom
moc gorących życzeń, zdrowia,
szczęścia i wszelkiej pomyślności
</div>
          <div className="newsDescription">
           dla abonentów z miejscowości Smardzew oraz Łosieniec.</div>
        <br />
        <div className="newsSignature">
Zespół a-Media.</div>
      </div> */}
        <Row>
          <Col>
            <div className="internet-heading">
              <h2>Internet światłowodowy</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="internet-text-box">
              <h3>Oferta dla każdego</h3>
              <p>
                Niezależnie od tego czy jesteś klientem indywidualnym czy
                posiadasz swoją firmę, jesteśmy w stanie przedstawić Ci ofertę,
                która spełni Twoje wymagania. Oferujemy łącza światłowodowe oraz
                bezprzewodowe od 59zł.
              </p>
            </div>
          </Col>
          <Col>
            <div className="internet-text-box">
              <h3>Najwyższa wydajność i niezawodność</h3>
              <p>
                Dzięki temu, że mamy dostęp do łącz operatorskich przez
                zbudowane przez nas sieci światłowodowe jesteśmy w stanie tak
                optymalizować sieć, aby uzyskać najwyższą wydajność.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
